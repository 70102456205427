<template>
    <div class="shop_foot width_center_1200">
        <shop-index-adv :adv="adv"></shop-index-adv>
        <ul>
            <li><router-link to="">ABOUT US</router-link></li>
            <li><router-link to="">CONTACT US</router-link></li>
            <li><router-link to="">PRIVACY POLICY</router-link></li>
            <li><router-link to="">RETURN POLICY</router-link></li>
            <li><router-link to="">SHIPPING INFORMATION</router-link></li>
            <li><router-link to="">TERMS AND CONDITIONS</router-link></li>
        </ul>
        <div class="foot_copyright">
            <p>Copyright © 2005-2025 MySite Ltd. All Rights Reserved. POWERED BY UEESHOP </p>
          <!--  <p>公安备案 粤公网安备 3201140666666号 | 粤ICP备06666号-6 | 增值电信业务经营许可证：粤B2-6666666 | 网络文化经营许可证：粤网文[2015] 1566-026号</p>-->
        </div>
    </div>
</template>

<script>
import ShopIndexAdv from "@/components/home/public/shop_index_adv.vue"
export default {
    components: {
        ShopIndexAdv,
    },
    props: {},
    data() {
      return {
          adv:{},
          info:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        get_foot_info:function(){
            this.$get(this.$api.homeGetFootInfo).then(res=>{
                this.adv = res.data.adv.adv[0];
                this.info = res.data.info;
            });
        }
    },
    created() {
        this.get_foot_info();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.shop_foot{
    padding-top: 10px;
    text-align: center;
    ul{
        margin:40px auto 0 auto;
        width: 1064px;
        border-top: 1px solid #efefef;
        padding-top: 40px;
    }
    ul li{
        float: left;
        margin-right: 40px;
        margin-bottom: 60px;
        font-size: 14px;
        a:hover{
            color:#ca151e;
        }
    }
    ul li:last-child{
        margin-right: 0px;
    }
    .foot_copyright{
        clear: both;
        font-size: 18px;
        line-height: 35px;
        padding-bottom: 30px;
    }
}
</style>